import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Drawer,
  List,
  IconButton,
  Button,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
} from "@mui/material";
import { styled } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import { useAdminState } from "../context/AdminContext";
import { logout } from "../services/auth";
import Logo from "../images/CPAxtra-Lotuss_Logo.png";
import { navigate } from "gatsby";
import {
  HomeIcon,
  UserGroupIcon,
  Squares2X2Icon,
  CodeBracketIcon,
  AcademicCapIcon,
} from "@heroicons/react/24/outline";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const HeaderRoot = styled("div")({
  flexGrow: 1,
  marginBottom: 70,
});

const DrawerContent = styled("div")(({ theme }) => ({
  width: 280,
  background: "#f4f3ef",
  height: "100vh",
  overflowY: "auto",
}));

const LogoImage = styled("img")({
  position: "relative",
  top: 15,
  width: 200,
  cursor: "pointer",
});

const Header = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isDashboardOpen, setDashboardOpen] = useState(false);
  const { profile } = useAdminState()?.login?.user || {};

  const toggleDrawer = (open) => () => setDrawerOpen(open);
  const toggleDashboard = () => setDashboardOpen(!isDashboardOpen);

  const onClickLogout = () => {
    if (typeof window !== "undefined") {
      logout();
      window.location = "/login";
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
    setDrawerOpen(false);
  };

  return (
    <HeaderRoot>
      <AppBar position="static" className="appbar">
        <Toolbar>
          <IconButton onClick={toggleDrawer(true)} color="inherit">
            <MenuIcon />
          </IconButton>
          <LogoImage src={Logo} alt="Logo" onClick={() => navigate("/")} />
          <Button
            className="logout-button"
            color="inherit"
            onClick={onClickLogout}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <DrawerContent>
          <List>
            <ListItem>
              <ListItemText primary={`Hi! ${profile?.given_name || ""}`} />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => handleNavigation("/")}>
              <HomeIcon className="navigation-icon" />
              <ListItemText primary="Home" />
            </ListItem>

            <ListItem button onClick={toggleDashboard}>
              <Squares2X2Icon className="navigation-icon" />
              <ListItemText primary="Dashboard" />
              {isDashboardOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={isDashboardOpen} timeout="auto" unmountOnExit>
              <div className="paddLeft">
                <List component="div" disablePadding>
                  <ListItem button onClick={() => handleNavigation("/")}>
                    <AcademicCapIcon className="navigation-icon" />
                    <ListItemText primary="PMO" />
                  </ListItem>
                </List>

                <List component="div" disablePadding>
                  <ListItem
                    button
                    onClick={() => handleNavigation("/dashboard/localDev")}
                  >
                    <CodeBracketIcon className="navigation-icon" />
                    <ListItemText primary="Local Dev" />
                  </ListItem>
                </List>
              </div>
            </Collapse>

            <ListItem button onClick={() => handleNavigation("/resources")}>
              <UserGroupIcon className="navigation-icon" />
              <ListItemText primary="Resources" />
            </ListItem>
          </List>
        </DrawerContent>
      </Drawer>
    </HeaderRoot>
  );
};

export default Header;
