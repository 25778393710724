import { UserManager } from "oidc-client-ts";

const oidcConfig = {
  authority: process.env.GATSBY_AUTHORITY_URL,
  client_id: process.env.GATSBY_CLIENT_ID,
  redirect_uri: process.env.GATSBY_REDIRECT_URL,
  post_logout_redirect_uri: process.env.GATSBY_LOGOUT_REDIRECT_URL,
  response_type: "code",
  scope: "openid profile email groups params",
};

const userManager = new UserManager({
  ...oidcConfig,
  code_challenge_method: "S256",
});

export function login() {
  userManager.signinRedirect();
}

export async function handleCallback() {
  try {
    const user = await userManager.signinRedirectCallback();

    // Save user data to localStorage
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    }

    return user;
  } catch (error) {
    console.error("Callback handling error:", error);
  }
}

export function logout() {
  // Clear user data from localStorage on logout
  localStorage.removeItem("user");
  userManager.signoutRedirect();
}

export function getUser() {
  return userManager.getUser();
}

// Optional helper function to retrieve user data from localStorage
export function getStoredUser() {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
}
