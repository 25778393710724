const createStore = ({ name = "" } = {}) =>
  (() => {
    const subscriptions = []
    const store = {
      isReady: false,
      name,
      dispatch: function () {
        throw new Error(`store ${this.name}: is NOT ready`)
      },
      getState: () => null,
      subscribe: function (callback) {
        subscriptions.push(callback)
      },
      _onStateUpdated: function () {
        subscriptions.forEach(fn => fn())
      },
      init: function ({ dispatch, getState }) {
        if (this.isReady) return

        this.isReady = true
        this.dispatch = dispatch
        this.getState = getState
      },
      unmount: function () {
        this.isReady = false
      },
    }

    return store
  })()

export const adminContextStore = createStore({ name: "adminContext" })
