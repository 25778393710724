import React, { useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Header from "./header";
import "./layout.css";
import { Wrapper } from "./styles";
import Snackbar from "../shared/Snackbar";
import { navigate } from "gatsby";
import { adminContextStore } from "../context/store/createStore";
import { SET_LOGIN } from "../context/actions/auth";
import { getUser } from "../services/auth";
import { getRoleType } from "../utils/roleChecker";

const Layout = ({ children }) => {
  const { dispatch } = adminContextStore;
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  const fetchUser = useCallback(async () => {
    try {
      const currentUser = await getUser();

      if (currentUser) {
        dispatch({
          type: SET_LOGIN,
          payload: {
            submitting: false,
            isLogin: true,
            user: currentUser,
            role: getRoleType(currentUser?.profile?.groups),
          },
        });
        navigate("/");
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (process.env.GATSBY_APP_ENV !== "development") {
      fetchUser();
    }
  }, [fetchUser]);

  return (
    <Wrapper>
      <Snackbar />
      <Header />
      <main>{children}</main>
      <footer>© {currentYear}, Lotuss PMO</footer>
    </Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
