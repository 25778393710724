const rolesToCheck = new Map([
  ["GG-TH-PMODashabord-SuperAdmin", "SuperAdmin"],
  ["GG-TH-PMODashabord-Admin", "Admin"],
  ["GG-TH-PMODashabord-ManagerUser", "Manager"],
]);

export const getRoleType = (userRoles) => {
  if (!Array.isArray(userRoles) || userRoles.length === 0) {
    return null;
  }

  const matchedRole = userRoles.find((role) => rolesToCheck.has(role));
  return matchedRole ? rolesToCheck.get(matchedRole) : null;
};
